.carousel-viewport {
  width: 100%;
}

.carousel {
  width: 100%;
  height: auto;
  object-fit: cover;
  mask-image: linear-gradient(
    to right,
    transparent,
    black 20%,
    black 80%,
    transparent
  );
  mask-mode: alpha;
}

.carousel-container {
  display: flex;
  flex-direction: row;
  width: 200%;
  position: relative;
  height: 147px;
  animation: slide 20s linear infinite;
  column-gap: 0;
  &:hover {
    animation-play-state: paused;
  }
}

.carousel-track {
  display: flex;
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100%;
  margin: 0;
}

.carousel-track-two {
  display: flex;
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: 100%;
  margin: 0;
}

.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  animation: scaling-back 0.5s;
  opacity: 0.5;
  transition: opacity 0.4s;
  &:hover {
    animation: scaling 0.5s;
    animation-fill-mode: forwards;
    opacity: 1;
  }

  border-radius: 20%;
}

.carousel-card > img {
  height: 80%;
  width: 80%;
  border-radius: 10%;
}

@keyframes slide {
  0% {
    left: 0%;
  }

  100% {
    left: -100%;
  }
}

@keyframes scaling {
  0% {
    scale: 1;
  }

  100% {
    scale: 1.1;
  }
}

@keyframes scaling-back {
  0% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}

@media (max-width: 1204px) {
  .carousel-container {
    padding-bottom: 40px;
    justify-content: space-around;
    width: 400%;
    animation: slide-two 20s linear infinite;
  }
  .carousel-track {
    justify-content: space-around;
    width: 200%;
  }
  .carousel-track-two {
    justify-content: space-around;
    width: 200%;
  }
  .carousel-card {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@media (max-width: 1204px) {
  @keyframes slide-two {
    0% {
      left: 0%;
    }

    100% {
      left: -200%;
    }
  }
}

@media (max-width: 480px) {
  .carousel-container {
    justify-content: space-around;
    width: 600%;
    height: 90px;
    animation: slide-two 20s linear infinite;
  }
  .carousel-track {
    justify-content: space-around;
    width: 300%;
  }
  .carousel-track-two {
    justify-content: space-around;
    width: 300%;
  }
  .carousel-card {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@media (max-width: 480px) {
  @keyframes slide-two {
    0% {
      left: 0%;
    }

    100% {
      left: -300%;
    }
  }
}
