.advisers-section-container {
  width: 100%;
  background-color: #f6f5f4;
  padding: 50px 50px;
  position: relative;
}

.advisers-section-content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
}

.advisers-section-text {
  padding-top: 20px;
  padding-bottom: 70px;
  text-align: center;
}

.advisers-section-members {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 50px;
}

@media (max-width: 1200px) {
  .advisers-section-text {
    margin-top: 50px;
  }
}

@media (max-width: 990px) {
  .advisers-section-container {
    padding: 50px 0;
  }
  .advisers-section-members {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 575px) {
  .advisers-section-container {
    padding: 50px 0;
  }
  .advisers-section-content {
    width: 100%;
  }
  .advisers-section-members {
    width: 100%;
    justify-content: space-between;
    grid-template-columns: 1fr;
  }
}
