.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.burger-menu img {
  height: 24px;
  width: 24px;
}

@media (min-width: 1025px) {
  .burger-menu {
    display: none;
  }
}

@media (max-width: 480px) {
  .burger-menu {
    align-items: center;
  }
  .burger-menu img {
    height: 18px;
    width: 20px;
  }
}
