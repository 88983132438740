.side-drawer {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #f6f5f4;
  display: flex;
  flex-direction: column;
  z-index: 1;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}

.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.drawer-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
}

.drawer-header::before {
  content: "";
  background: linear-gradient(
    to left,
    #ee3200,
    #f6590c,
    #ff861c,
    #ff861c,
    #f6590c,
    #ee3200
  );
  background: -webkit-linear-gradient(
    to left,
    #ee3200,
    #f6590c,
    #ff861c,
    #ff861c,
    #f6590c,
    #ee3200
  );
  height: 2px;
  position: absolute;
  bottom: 90%;
  left: 0;
  width: 100%;
}

.drawer-logo {
  width: 150px;
  height: auto;
}

.drawer-links ul {
  display: flex;
  flex-direction: column;
  gap: 30px;
  list-style: none;
  padding: 0;
}

.drawer-link {
  width: 100%;
}

.drawer-link a {
  display: flex;
  text-decoration: none;
  color: black;
  width: 100%;
  justify-content: space-between;
}

.drawer-link a:visited {
  color: black;
}

.span {
  font-size: 18px;
}

.chevron {
  justify-content: flex-end;
  width: 20px;
  height: 20px;
  opacity: 0;
}

.drawer-link a:hover .chevron {
  background-image: url(../../../assets/chevron.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  transition: opacity 0.5s;
  opacity: 1;
}

.drawer-link a:hover .span {
  font-weight: 600;
}

@media (min-width: 1024px) {
  .drawer-logo {
    width: 198px;
    height: auto;
  }
  .drawer-header {
    padding: 30px 90px 0px 60px;
  }

  .drawer-links {
    padding: 50px 90px 0px 60px;
  }
}
