.s-one {
  height: 700px;
  width: 100%;
  position: relative;
}

.s-one-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-y: hidden;
  margin: 0 auto;
  padding: 0 50px;
  max-width: 1200px;
}

.s-one-col-right {
  align-items: center;
  max-width: 450px;
  width: 100%;
}

.s-one-col-right h2 {
  width: 100%;
}

.s-one-col-right p {
  width: 100%;
}

.s-one-col-left {
  height: 100%;
  width: 100%;
  flex: 2;
}

@media (max-width: 480px) {
  .s-one-container {
    flex-direction: column-reverse;
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .s-one-col-right {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
