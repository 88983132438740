body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
}

*,
:after,
:before {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
}

a {
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.content {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

.spacer {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  flex: 1 1;
}

h1 {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-size: 56px;
  line-height: 70px;
}

h2 {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  padding-bottom: 40px;
  font-size: 41px;
  line-height: 44px;
}

p {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

@media (max-width: 480px) {
  h1,
  h2 {
    font-size: 30px;
    line-height: 35px;
  }
  p {
    font-size: 16px;
    line-height: 21px;
  }
}

.py-5 {
  padding: 1.25rem 0; /* 20px */
}

.font-medium {
  font-weight: 500;
}

.leading-7 {
  line-height: 1.75rem; /* 28px */
}

.pl-8 {
  padding-left: 2rem; /* 32px */
}

.block {
  display: block;
}

.w-full	{
  width: 100%;
}
