.adviser-member {
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.adviser-image-holder {
  justify-content: center;
  position: relative;
  display: flex;
  background-image: url("../../assets/team/light-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  transition: all 0.3s ease-out;
  width: 250px;
}

.adviser-image-holder:hover {
  background-image: url("../../assets/team/dark-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: transform 0.4s, -webkit-transform 0.4s ease-in-out;
  filter: drop-shadow(0px 7px 10px rgba(0, 0, 0, 0.35));
}

.adviser-default-img {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  width: 250px;
}

.adviser-image-holder:hover::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 250px;
  height: 250px;
  background-image: url("../../assets/team/In.png");
  background-repeat: no-repeat;
  background-position: center;
  animation: opacityy 0.7s;
}

@keyframes opacityy {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.info p {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.info small {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
}

@media (max-width: 990px) {
  .adviser-member {
    justify-content: center;
    align-items: center;
  }
}
