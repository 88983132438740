.s-four {
  height: 700px;
  width: 100%;
  position: relative;
  background-color: #f6f5f4;
  overflow-x: hidden;
}

.s-four-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  /* overflow-y: hidden; */
  margin: 0 auto;
  padding: 0 50px;
  max-width: 1200px;
  gap: 100px;
}

.s-four-col-right {
  align-items: center;
  max-width: 450px;
  width: 100%;
  flex: 1;
  margin: 0 auto;
}

.s-four-col-left {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  background-image: url(../../assets//section-fourr.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 450px;
  width: 100%;
  height: 100%;
}

@media (max-width: 480px) {
  .s-four-container {
    flex-direction: column-reverse;
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;
    gap: 10px;
  }
  .s-four-col-right {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .s-four-col-lef {
    align-items: center;
    justify-content: center;
  }
}

.typewriter {
  flex: 1;
  font-size: 80px;
  color: white;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  opacity: 1;
  filter: drop-shadow(1px 1px 15px #63231383);
}

@media (max-width: 480px) {
  .typewriter {
    font-size: 60px;
  }
}
