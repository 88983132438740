.header {
  padding: 20px 0;
  background-color: #f6f5f4;
  width: 100%;
}

.header-navigation ul {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  list-style: none;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.header-navigation a {
  color: black;
}

.header-navigation a:visited {
  color: black;
}

.header-navigation ul li:last-child a {
  color: white;
  border: 1px solid #ee3100;
  background: linear-gradient(to right, #ff8d38, #fc8133, #f9712d, #f8692a);
  background: -webkit-linear-gradient(
    to right,
    #ff8d38,
    #fc8133,
    #f9712d,
    #f8692a
  );
  padding: 5px 10px;
  border-radius: 5px;
}

.header-navigation ul li:last-child a:hover {
  filter: drop-shadow(1px 1px 3px rgba(119, 117, 117, 0.7));
}

.header-navigation a:hover {
  text-shadow: 0 0.3px 0;
}

@media (max-width: 1024px) {
  .header {
    padding: 50px 50px;
  }
  .header-navigation {
    display: none;
  }
}

@media (max-width: 480px) {
  .header-logo {
    width: 150px;
    height: auto;
  }
  .header {
    padding: 0;
  }
  .header-content {
    padding: 20px;
  }
}
