.supported-by-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.supported-title {
  text-align: center;
  padding-top: 50px;
}

.supported-by-content {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  padding: 0 50px 50px 50px;
}

@media (max-width: 1024px) {
  .supported-by-content {
    flex-direction: column;
  }
}

.support-img-container-left,
.support-img-container-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.support-img-container-left img,
.support-img-container-right img {
  max-width: 100%;
  scale: 0.6;
}
