.s-two {
  height: 700px;
  width: 100%;
  position: relative;
  background-color: #f6f5f4;
}

.s-two-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow-y: hidden;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 70px;
  max-width: 1200px;
}

.s-two-col-left {
  flex: 1;
}

.s-two-col-left h2 {
  margin: 0 auto;
  width: 60%;
}

.s-two-col-right {
  flex: 2;
}

@media (max-width: 480px) {
  .s-two-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .s-two-col-left h2 {
    width: 100%;
  }
}
