.footer {
  background-color: #f6f5f4;
}

.footer-container::before {
  content: "";
  background: linear-gradient(
    to left,
    #ee3200,
    #f6590c,
    #ff861c,
    #ff861c,
    #f6590c,
    #ee3200
  );
  background: -webkit-linear-gradient(
    to left,
    #ee3200,
    #f6590c,
    #ff861c,
    #ff861c,
    #f6590c,
    #ee3200
  );
  height: 5px;
  position: absolute;
  top: 0;
  width: 100%;
}

.footer-content {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
  margin-top: 20px;
}

.footer-col-left {
  flex: 2;
  max-width: unset;
}

.footer ul {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  list-style: none;
  padding: 0;
}

.footer a {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #757575;
}

.footer a:visited {
  color: #757575;
}
.footer a:hover {
  text-shadow: 0 0.3px 0;
}

.footer-col-right img {
  width: 198px;
  height: 24px;
}

.footer-col-right {
  align-items: end;
}

.footer-underline {
  display: flex;
  max-width: 1100px;
  width: 100%;
  justify-content: right;
  border-top: 1.2px solid #e0e0e0;
  margin-bottom: 20px;
}

.footer-underline p {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #757575;
}

@media (max-width: 1024px) {
  .footer-content {
    flex-direction: column;
  }
  .footer-col-left ul {
    align-items: center;
    flex-direction: column;
  }
  .footer-col-right {
    padding: 25px 0 15px 0;
    align-items: center;
  }
  .footer-col-right img {
    width: 150px;
    height: auto;
  }
  .footer-underline {
    padding: 0 50px;
    justify-content: center;
  }
}
