.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  min-height: 700px;
  width: 100vw;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #f6f5f4;
  z-index: 0;
}

@media (max-width: 480px) {
  .hero {
    height: 85vh;
  }
}

.hero-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  margin: 0 auto;
  padding: 0 50px;
  z-index: -100;
}

.hero-content {
  display: flex;
  flex-direction: row;
  height: 100%;
  row-gap: 1rem;
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
}

.hero-col-left {
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.hero-col-right {
  height: 100%;
  width: 100%;
  flex: 2;
}

@media (max-width: 480px) {
  .hero-container {
    padding: 50px 0;
  }
  .hero-content {
    padding: 0 20px;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .hero-col-left {
    flex: 1;
  }

  .hero-col-right {
    flex: 2;
  }
}
