.close-icon {
  position: relative;
  display: inline-flex;
  border-radius: 50%;
  color: black;
  justify-content: center;
  align-items: center;
}

.close-icon:hover {
  outline: 2px solid black;

  outline-offset: 3px;
  border-radius: 2rem;
  transition-property: outline-offset;
  transition-duration: 0.1s;
}
