.s-three {
  height: 700px;
  width: 100%;
  position: relative;
}

.s-three-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  /* overflow-y: hidden; */
  margin: 0 auto;
  padding: 0 50px;
  max-width: 1200px;
  gap: 100px;
}

.s-three-col-left {
  flex: 1;
  width: 100%;
  max-width: 450px;
}

.s-three-col-right {
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
}

.s-three-img {
  max-width: 70%;
  height: auto;
  margin: 0 auto;
}

.s-three-col-left h2 {
  width: 100%;
}

@media (max-width: 480px) {
  .s-three-container {
    flex-direction: column;
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    gap: 10px;
  }
  .s-three-col-left {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
